/* dependencies */
import React from "react"
import { Icon } from "./elements"
import Breadcrumb from "./breadcrumb"
import { Link } from "react-router-dom"
import { array } from "fast-web-kit"
import { useLanguage } from "../context/language"
import { useAuth } from "../context/auth"
import menus from "../utils/menu"
import { useCustomState } from "../context/state"
import { useApplication } from "../context/application"
import { text } from "../utils"
import { string } from "fast-web-kit"
import { useTheme } from "../context/theme"

/* menu */
export type menu = {
    rank: number
    icon: string
    link: string
    title: string
    visible: boolean
    subMenu?: subMenu[]
    hasSubMenu?: boolean
}

/* sub menu */
export type subMenu = {
    title: string
    link: string
    visible: boolean
}

/* sidebar */
const Sidebar: React.FunctionComponent = React.memo(() => {

    const { theme } = useTheme()
    const { language } = useLanguage()
    const { logout, user } = useAuth()
    const { translate } = useLanguage()
    const { clearState } = useCustomState()
    const { application } = useApplication()

    // const userLanguage = storage.retrieve("language")
    const [activMenu, setActiveMenu] = React.useState<number>(-1)
    const [icon, setIcon] = React.useState<string>("dashboard")
    const [title, setTitle] = React.useState<string>("dashboard")
    const [activeLink, setActiveLink] = React.useState<string>(window.location.pathname)

    window.onclick = (event: any): void => {

        // getting current pathname
        const currentPathname: string = window.location.pathname

        // changing active nav link
        setActiveLink(currentPathname)

        // update breadcrumb
        updateBreadcrumb()

        // close active sidebar content
        if ((event.target.tagName !== "A") && (event.target.tagName !== "SPAN") && (event.target.tagName !== "I"))
            setActiveMenu(-1)

    }

    // update breadcrumb
    function updateBreadcrumb(): void {
        const currentPathname: string = window.location.pathname
        // eslint-disable-next-line
        menus.map((menu: menu) => {
            if (currentPathname.includes(menu.title.replace(/ /g, "-").toLowerCase())) {
                setTitle(menu.title)
                setIcon(menu.icon)
            }
        })
    }

    React.useEffect(() => {
        updateBreadcrumb()
        // eslint-disable-next-line
    }, [])

    // function for toggling sidebar content
    const toggleSidebarContent = (index: number): void => {
        // toggle sibar nav content
        if (index === activMenu)
            setActiveMenu(-1)
        else
            setActiveMenu(index)
    }

    // function for rendering menu
    const renderMenu = React.useCallback(() => {
        return array.sort(menus, "asc", "rank").map((menu: menu, index: number) => {
            if (menu.visible)
                return (
                    <li className="nav-item" key={index} onClick={() => {
                        toggleSidebarContent(index)
                        if (!menu.hasSubMenu) {
                            application.closeSidebar()
                            if (!string.toKebabCase(menu.title)) {
                                clearState()
                            }
                        }
                    }}>
                        <Link
                            to={menu.link}
                            className={`nav-link ${activeLink.includes(`/${string.toKebabCase(menu.title)}/`) ? "active" : ""}`}
                        >
                            <Icon type="rounded" name={menu.icon} />
                            <span>{translate(menu.title)}</span>
                        </Link>
                        {
                            menu.hasSubMenu && menu.subMenu
                                ?
                                <ul className={`nav-content ${activMenu === index ? "show" : ""}`}>
                                    {
                                        menu.subMenu.sort((a, b) => {
                                            if (a.title.length < b.title.length) {
                                                return -1;
                                            }
                                            if (a.title.length > b.title.length) {
                                                return 1;
                                            }
                                            return 0;
                                        }
                                        ).map((subMenu: subMenu, indexTwo: number) => {
                                            if (subMenu.visible)
                                                return (
                                                    <li key={indexTwo}>
                                                        <Link to={subMenu.link} className={`${activeLink.includes(subMenu.link) ? "active" : ""}`} onClick={() => {
                                                            application.closeSidebar()
                                                            if (!activeLink.includes(subMenu.link)) {
                                                                clearState()
                                                            }
                                                        }}>
                                                            <Icon name="chevron_right" type="rounded" />
                                                            <span>{translate(subMenu.title)}</span>
                                                        </Link>
                                                    </li>
                                                )
                                            else
                                                return null
                                        })
                                    }
                                </ul>
                                : null
                        }
                    </li>
                )
            else
                return null
        })
        // eslint-disable-next-line
    }, [activeLink, activMenu, user, theme, language])

    // returning component view
    if (user)
        return (
            <>
                <Breadcrumb icon={icon} title={title} />
                <aside id="sidebar" className="sidebar hide-on-print">
                    <Icon name="menu_open" type="rounded toggle-sidebar-btn" onClick={application.toggleSidebar} />
                    <Link to="/settings/edit-profile" className="logo-container">
                        <Icon name="person" type="rounded" />
                        <div className="username">
                            {text.reFormat(user.name)}
                        </div>
                    </Link>
                    <ul className="sidebar-nav" id="sidebar-nav">
                        {renderMenu()}
                        <li className="nav-item">
                            <Link to="#" className="nav-link" onClick={logout}>
                                <Icon name="logout" type="rounded" />
                                <span>{translate("Logout")}</span>
                            </Link>
                        </li>
                    </ul>
                    <div id="overlay" className="overlay" onClick={application.toggleSidebar}></div>
                </aside>
            </>
        )
    return null

})

/* exporting component */
export default Sidebar