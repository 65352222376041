import { can } from "..";
import { MenuType } from "../../types";


const menus: MenuType[] = [

    {
        rank: 1,
        link: "/dashboard",
        icon: "dashboard",
        title: "dashboard",
        visible: can("view_dashboard_menu")
    },

    {
        rank: 2,
        link: "#",
        icon: "inventory_2",
        hasSubMenu: true,
        title: "inventory",
        visible: can("view_inventory_menu"),
        subMenu: [
            {
                title: "new category",
                visible: can("create_category"),
                link: "/inventory/category-form",
            },
            {
                title: "list categories",
                visible: can("list_categories"),
                link: "/inventory/category-list",
            },
            {
                title: "new product",
                visible: can("create_product"),
                link: "/inventory/product-form",
            },
            {
                title: "list products",
                visible: can("list_products"),
                link: "/inventory/product-list",
            },
            {
                title: "new stock adjustment",
                visible: can("adjust_product_stock"),
                link: "/inventory/stock-adjustment-form",
            },
            {
                title: "list stock adjustments",
                visible: can("list_stock_adjustments"),
                link: "/inventory/stock-adjustment-list",
            },
            {
                title: "request stock",
                visible: can("request_stock"),
                link: "/inventory/stock-request-form"
            },
            {
                title: "list stock requests",
                visible: can("list_stock_requests"),
                link: "/inventory/stock-request-list",
            },
            {
                title: "new store",
                link: "/inventory/store-form",
                visible: can("create_store"),
            },
            {
                title: "list stores",
                visible: can("list_stores"),
                link: "/inventory/store-list",
            }
        ]
    },

    {
        rank: 3,
        link: "#",
        icon: "group",
        hasSubMenu: true,
        title: "customer",
        visible: can("view_customer_menu"),
        subMenu: [
            {
                title: "new customer",
                visible: can("create_customer"),
                link: "/customer/form",
            },
            {
                link: "/customer/list",
                title: "list customers",
                visible: can("list_customers"),
            }
        ]
    },

    {
        rank: 4,
        link: "#",
        hasSubMenu: true,
        icon: "point_of_sale",
        title: "point of sale",
        visible: can("view_point_of_sale_menu"),
        subMenu: [

            {
                title: "new sale",
                visible: can("create_sale"),
                link: "/point-of-sale/sale-form",
            },
            {
                title: "list sales",
                visible: can("list_sales"),
                link: "/point-of-sale/sale-list",
            },
            {
                title: "new invoice",
                link: "/point-of-sale/order-form",
                visible: can("create_order"),
            },
            {
                title: "list invoices",
                link: "/point-of-sale/order-list",
                visible: can("list_orders"),
            },
            {
                title: "new quote",
                link: "/point-of-sale/proforma-form",
                visible: can("create_proforma"),
            },
            {
                title: "list quotes",
                link: "/point-of-sale/proforma-list",
                visible: can("list_proformas"),
            }
        ]
    },

    {
        rank: 5,
        link: "#",
        icon: "local_shipping",
        hasSubMenu: true,
        title: "procurement",
        visible: can("view_procurement_menu"),
        subMenu: [

            {
                title: "new supplier",
                link: "/procurement/supplier-form",
                visible: can("create_supplier"),
            },
            {
                title: "list suppliers",
                visible: can("list_suppliers"),
                link: "/procurement/supplier-list",
            },
            {
                title: "new purchase",
                link: "/procurement/purchase-form",
                visible: can("create_purchase"),
            },
            {
                title: "list purchases",
                visible: can("list_purchases"),
                link: "/procurement/purchase-list",
            }
        ]
    },

    {
        rank: 6,
        link: "#",
        icon: "money",
        hasSubMenu: true,
        title: "finance",
        visible: can("view_finance_menu"),
        subMenu: [

            {
                title: "new expense type",
                link: "/finance/expense-type-form",
                visible: can("create_expense_type"),
            },
            {
                title: "list expense types",
                link: "/finance/expense-type-list",
                visible: can("list_expense_types"),
            },
            {
                title: "new expense",
                link: "/finance/expense-form",
                visible: can("create_expense"),
            },
            {
                title: "list expenses",
                link: "/finance/expense-list",
                visible: can("list_expenses"),
            },
            {
                title: "new debt",
                link: "/finance/debt-form",
                visible: can("create_debt"),
            },
            {
                title: "list debts",
                link: "/finance/debt-list",
                visible: can("list_debts"),
            },
            {
                title: "list debt histories",
                link: "/finance/debt-history-list",
                visible: can("list_debt_histories"),
            },
            {
                title: "new payment",
                link: "/finance/payment-form",
                visible: can("create_payment"),
            },
            {
                title: "list payments",
                link: "/finance/payment-list",
                visible: can("list_payments"),
            },
        ]
    },

    {
        rank: 7,
        link: "#",
        icon: "manage_accounts",
        hasSubMenu: true,
        title: "employee",
        visible: can("view_employee_menu"),
        subMenu: [
            {
                title: "new role",
                link: "/employee/role-form",
                visible: can("create_role"),
            },
            {
                title: "list roles",
                link: "/employee/role-list",
                visible: can("list_roles"),
            },
            {
                title: "new employee",
                link: "/employee/form",
                visible: can("create_employee"),
            },
            {
                link: "/employee/list",
                title: "list employees",
                visible: can("list_employees"),
            }
        ]
    },

    {
        rank: 8,
        link: "#",
        icon: "receipt_long",
        hasSubMenu: true,
        title: "report",
        visible: can("view_report_menu"),
        subMenu: [
            {
                title: "new report",
                link: "/report/form",
                visible: can("create_report"),
            },

            {
                title: "income statement",
                link: "/report/income-statement",
                visible: can("create_income_statement"),
            },
        ]
    },

    {
        rank: 9,
        link: "#",
        icon: "storefront",
        hasSubMenu: true,
        title: "branch",
        visible: can("view_branch_menu"),
        subMenu: [
            {
                title: "new branch",
                link: "/branch/form",
                visible: can("create_branch"),
            },
            {
                link: "/branch/list",
                title: "list branches",
                visible: can("list_branches"),
            },
            {
                link: "/branch/activities",
                title: "list branch activities",
                visible: can("list_branch_activities"),
            },
            {
                link: "/branch/data",
                title: "view branch data",
                visible: can("view_branch_data"),
            },
            {
                link: "/branch/settings",
                title: "branch settings",
                visible: can("access_branch_settings"),
            },

        ]
    },

    {
        rank: 10,
        visible: true,
        icon: "settings",
        title: "settings",
        link: "#",
        hasSubMenu: true,
        subMenu: [
            {
                visible: true,
                title: "change password",
                link: "/settings/change-password",
            },
            {
                visible: true,
                title: "edit profile",
                link: "/settings/edit-profile",
            },
            {
                visible: true,
                title: "employee settings",
                link: "/settings/employee",
            }
        ]
    },

]

export default menus