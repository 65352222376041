
const LanguageResources: Record<string, Record<string, string>> = {

    swahili: {
        protect_account: "linda akaunti",
        allow_notifications: "ruhusu upokeaji wa taarifa",
        allow_camera_access: "ruhusu matumizi ya kamera",
        allow_contacts_access: "ruhusu matumizi ya majina",
        allow_location_access: "ruhusu matumizi ya eneo",
        edit_profile: "rekebisha wasifu",
        change_password: "badili neno la siri",
        appearance: "muonekano",
        light: "mwanga",
        dark: "giza",
        automatic: "kiotomatiki",
        account_settings: "mpangilio wa akaunti",
        settings: "mipangilio",
        employee_form: "fomu ya mfanyakazi",
        employees: "wafanyakazi",
        inventory: "stoo",
        point_of_sale: "Mahali pa Kuuza",
        procurement: "ununuzi",
        customer: "mteja",
        report: "ripoti",
        branch: "tawi",
        dashboard: "dashibodi",
        employee: "mfanyakazi",
        finance: "fedha",
        inventory_settings: "Mipangilio ya stoo",
        pos_settings: "Mipangilio ya Mauzo",
        procurement_settings: "Mipangilio ya ununuzi",
        finance_settings: "Mipangilio ya Fedha",
        customer_settings: "Mipangilio ya Wateja",
        employee_settings: "Mipangilio ya Wafanyakazi",
        branch_settings: "Mipangilio ya Tawi",
        login: "ingia",
        login_sessions: "uingiaji kwenye mfumo",
        back: "nyuma",
        language: "lugha",
        swahili: "kiswahili",
        english: "kiingereza",
        view_profile: "tazama wasifu",
        name: "jina",
        phone_number: "namba ya simu",
        create: "tengeneza",
        update: "rekebisha",
        menu: "menyu",
        ip_address: "anwani ya IP",
        device: "kifaa",
        device_name: "jina la kifaa",
        login_date: "tarehe ya kuingia",
        logout_date: "tarehe ya kutoka",
        remarks: "maelezo",
        logout: "kutoka",
        "unrecognized_session?": "hutambui kifaa hiki?",
        session_detail: "maelezo ya uingiaji",
        super_admin: "msimamizi wa juu",
        system_admin: "msimamizi wa mfumo",
        branch_owner: "mmiliki wa tawi",
        this: "hii",
        loading: "inatafuta",
        error: "kuna kosa",
        "forgot_password?": "Umesahau neno la siri?",
        forgot_password: "Umesahau neno la siri",
        account: "Akaunti",
        welcome_back: "Karibu tena",
        password: "Neno la siri",
        enter_your_name_or_phone_number: "Ingiza jina lako au namba ya simu",
        enter_your_account_password: "Ingiza neno la siri la akaunti yako",
        back_to_login: "Rudi kwa kuingia",
        send_verification_code: "Tuma nambari ya uthibitisho",
        place: "eneo",
        unknown: "haijulikani",
        phone_number_verified: "Nambari ya simu imethibitishwa",
        account_protection: "usalama wa akaunti",
        account_type: "Aina ya Akaunti",
        role: "Jukumu",
        "n/a": "Haipo",
        employee_detail: "taarifa za Mfanyakazi",
        yes: "ndio",
        no: "hapana",
        enabled: "Imewezeshwa",
        disabled: "Imezimwa",
        delete_account: "futa akaunti",
        delete: "futa",
        cancel: "ghairi",
        account_deletion: "ufutaji wa akaunti",
        "are_you_sure_you_want_to_delete_your_account?": "Una uhakika unataka kufuta akaunti yako?",
        allow_barcode_scanning: "ruhusu matumizi ya barcode",
        allow_barcode_sound: "ruhusu sauti ya barcode",
        allow_barcode_vibration: "ruhusu msisimko wa barcode",
        new_category: "jamii mpya ya bidhaa",
        list_categories: "orodhesha jamii ya bidhaa",
        category_form: "fomu ya jamii ya bidhaa",
        new_product: "bidhaa mpya",
        list_products: "orodhesha bidhaa",
        product_form: "fomu ya bidhaa",
        stock_taking: "mahesabu ya bidhaa",
        request_stock: "omba bidhaa",
        stock_adjustments: "marekebisho ya bidhaa",
        stock_request_form: "fomu ya ombi la bidhaa",
        stock_request_list: "orodhesha maombi ya bidhaa",
        enter_name: "ingiza jina",
        upload_or_import_from_excel: "pakia au ingiza kutoka Excel",
        upload_from_excel: "pakia kutoka Excel",
        import_from_excel: "ingiza kutoka Excel",
        required: "inahitajika",
        "p.o.box": "sanduku la posta",
        opening_time: "muda wa kufungua",
        closing_time: "muda wa kufunga",
    }

};

export default LanguageResources