/* requiring dependencies */
import React from "react"
import { ChildrenType } from "../types"

/* icon */
export type icon = {
    type?: "rounded" | "outlined" | "sharp" | string
    name: string
    position?: "right" | "left" | "prefix"
    rotate?: boolean
    onClick?: any
}

/* icon */
export const Icon: React.FunctionComponent<icon> = React.memo((props: icon) => (
    <i className={`material-symbols-${props.type ? props.type : "rounded"} hide-on-print ${props.position} ${props.rotate ? "rotate" : ""} `} onClick={props.onClick}>{props.name}</i>
))

export const ActionButtonContainer: React.FunctionComponent<ChildrenType> = React.memo(({ children }) => (
    <div className="action-button">
        {children}
    </div>
))