import StateType from "."

const initialState: StateType = {

    clear: false,
    id: "",
    edit: false,
    loading: false,
    disabled: false,

    account: "",
    accountError: "",
    password: "",
    passwordError: "",
    name: "",
    nameError: "",
    phoneNumber: "",
    phoneNumberError: "",
    tinNumber: "",
    tinNumberError: "",
    vrnNumber: "",
    vrnNumberError: "",
    email: "",
    emailError: "",
    region: "",
    regionError: "",
    district: "",
    districtError: "",
    ward: "",
    wardError: "",
    website: "",
    websiteError: "",
    barcode: "",
    barcodeError: "",
    stock: "",
    stockError: "",
    quantity: "",
    quantityError: "",
    buyingPrice: "",
    buyingPriceError: "",
    sellingPrice: "",
    sellingPriceError: "",
    stockAlert: "",
    stockAlertError: "",
    code: "",
    codeError: "",
    placement: "",
    placementError: "",
    unitOfMeasure: "pcs",
    unitOfMeasureError: "",
    expireDate: "",
    expireDateError: "",
    store: null,
    stores: [],
    storeId: "",
    storeName: "",
    storeNameError: "",
    product: null,
    products: [],
    productId: "",
    productName: "",
    productNameError: "",
    category: null,
    categories: [],
    categoryId: "",
    categoryName: "",
    categoryNameError: "",

    files: [],
    file: null,
    filesError: "",
    image: "",
    imageError: "",
    list: [],
    type: "",
    typeError: "",
    remarks: "",
    remarksError: "",
    newStock: "",
    newStockError: "",
    adjustmentQuantity: "",
    adjustmentQuantityError: "",
    pobox: "",
    poboxError: "",
    totalAmount: "",
    totalAmountError: "",
    paidAmount: "",
    paidAmountError: "",
    date: "",
    dateError: "",
    supplier: null,
    suppliers: [],
    supplierId: "",
    supplierName: "",
    supplierNameError: "",
    customer: null,
    customers: [],
    customerId: "",
    customerName: "",
    customerNameError: "",
    expense_type: null,
    expense_types: [],
    expense_typeId: "",
    expense_typeName: "",
    expense_typeNameError: "",
    permissions: [],
    role: null,
    roles: [],
    roleId: "",
    roleName: "",
    roleNameError: "",
    branch: null,
    branches: [],
    branchId: "",
    branchName: "",
    branchNameError: "",
    reference: "",
    referenceError: "",
    receiptIsProvided: "",
    receiptIsProvidedError: "",
    user: null,
    users: [],
    userId: "",
    userName: "",
    userNameError: "",
    serviceFee: "",
    serviceFeeError: "",
    days: "",
    daysError: "",
    title: "",
    titleError: "",
    status: "",
    statusError: "",
    productSellingPrice: "",
    sales: [],
    page: 1,
    pages: [],
    sort: "",
    order: 1,
    condition: "all",
    module: "list",
    collection: "list",
    limit: 100,
    nextPage: 0,
    limits: [],
    previousPage: 0,
    ids: [],
    searchKeyword: "",
    adjustments: [],
    adjustment: null,
    purchase: null,
    purchases: [],
    expense: null,
    expenses: [],
    debt: null,
    debts: [],
    payment: null,
    payments: [],
    sale: null,
    oldPassword: "",
    oldPasswordError: "",
    passwordConfirmation: "",
    passwordConfirmationError: "",
    theme: "",
    themeError: "",
    language: "",
    languageError: "",
    barcodeSound: "no",
    barcodeAllowed: "no",
    barcodeVibration: "no",
    twoFactorAuthenticationEnabled: "no",
    dataObject: null,
    activity: null,
    activities: [],
    dateTo: "",
    dateToError: "",
    reportType: "",
    reportTypeError: "",
    remainAmount: "",
    remainAmountError: "",
    debt_history: null,
    debt_histories: [],
    year: new Date().getFullYear(),
    yearError: "",
    currencyFormat: "0,0",
    orders: [],
    companyName: "",
    companyNameError: "",
    country: "tanzania",
    countryError: "",
    discount: "",
    discountError: "",
    tax: "",
    taxError: "",
    request: null,
    requests: [],
    orderNumber: "",
    primaryColor: "#0066CC",
    primaryColorError: "",
    fontFamily: "Google Sans",
    fontFamilyError: "",
    fontSize: "0.875rem",
    fontSizeError: "",
    invoiceValidity: "",
    invoiceValidityError: "",
    proformaValidity: "",
    proformaValidityError: "",
    remarksTwo: "",
    remarksTwoError: "",
    paperSize: "",
    paperSizeError: "",
}

export default initialState