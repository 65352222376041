import StateType from "."
import { ReducerActionType } from "../types"
import initialState from "./initial-state"

const Reducer = (state: StateType, action: ReducerActionType): StateType => {
    try {

        let newStateValues = { ...state, ...action }
        const stateKeys = Object.getOwnPropertyNames(state)
        const actionKeys = Object.getOwnPropertyNames(action)

        for (const actionKey of actionKeys) {

            const actionErrorKey = `${actionKey}Error`

            if (actionKey.includes("Error"))
                newStateValues = { ...newStateValues, disabled: true }

            else if (stateKeys.includes(actionErrorKey) && stateKeys.includes(actionKey))
                newStateValues = { ...newStateValues, disabled: false, [actionErrorKey]: "" }

            else if (actionKey === "clear")
                newStateValues = initialState

        }

        return newStateValues

    } catch (error) {
        console.log((error as Error).message)
        return state
    }
}

export default Reducer