import { array, encryption, string, number as myNumber } from "fast-web-kit"
import { AccountType, EncryptionType, ServerResponseType } from "../types"
import PermissionNameType from "./permissions"
import { toast } from "react-toastify"
import moment from "moment"

export const setPageTitle = (title: string) => document.title = title
export const pageNotFound = "/page-not-found"

const encryptionType = "hex"
export const applicationName = "ONEPOS"
const initializationVector = "2d52550dc714656b"
const encryptionKey = "abcdefghijkLMNOPQrstuvwXyZONEPOS"

export const endPoint: string = "api/"
export const encryptionEnabled: boolean = false
export const domain: string = "https://onepostz.xyz"
export const serverIPAdress: string = "http://127.0.0.1:8080"

const getServerURL = () => {
    if (process.env.NODE_ENV === "production")
        return domain
    return serverIPAdress
}

export const serverURL: string = getServerURL()

export const text = {
    capitalize: (text: string) => string.capitalize(text),
    reFormat: (text: string) => string.capitalize(string.removeCase(text, "snake_case")),
    format: (text: string) => string.removeCase(string.removeExtraWhitespace(text), "snake_case"),
    abbreviate: (text: string) => (string.getAbbreviation(string.removeCase(text, "snake_case"))).substring(0, 3)
}

// decryption function
export const decrypt = (data: EncryptionType | ServerResponseType) => (
    encryption.decrypt(
        data as EncryptionType,
        encryptionKey,
        initializationVector,
        encryptionType
    )
)

// encryption function
export const encrypt = (data: any): EncryptionType => (
    encryption.encrypt(
        data,
        encryptionKey,
        initializationVector,
        encryptionType
    )
)

export const storage = {
    clear: () => localStorage.clear(),
    remove: (key: string) => localStorage.removeItem(key),
    retrieve: (key: string) => {
        try {
            const payload: string | null = localStorage.getItem(key)
            if (payload) {
                const data = decrypt({ payload: JSON.parse(payload) })
                return data
            }
            return null
        } catch (error) {
            return null
        }
    },
    store: (key: string, data: any) => localStorage.setItem(key, JSON.stringify(encrypt(data).payload)),
}

export const user = storage.retrieve("user")

export const checkUserRole = (role: AccountType): boolean => {
    try {

        if (user) {
            const { account_type } = user
            return account_type === role
        }

        return false
    } catch (error) {
        return false
    }
}

export const isAdmin = () => {
    try {
        if (user) {
            return !(["owner", "employee"].includes(user.account_type))
        }
        return false
    } catch (error) {
        return false
    }
}

export const can = (permission: PermissionNameType): boolean => {
    try {

        if (user) {
            if (checkUserRole("onepos") || (permission === "free_access"))
                return true

            if (array.elementExist(user.permissions, permission))
                return true
        }

        return false

    } catch (error) {
        toast((error as Error).message)
        return false
    }
}

export const number = {
    format: (number: any) => {
        if ((typeof number === "string") || (typeof number) === "number") {
            number = number.toString()
            let formatedNumber: string = ""

            if (number.includes("."))
                return number.replace(/^(\d+(?:,\d{3})*\.\d+)[.\d]*$/, '$1').replace(/[^0-9,.]/g, '')

            if (number.length <= 4)
                formatedNumber = myNumber.format(number)

            if (number.length > 4)
                formatedNumber = myNumber.format(myNumber.reFormat(number))

            return formatedNumber.replace(/[^0-9,.-]/g, '')
        }
        else
            return number

    },
    reFormat: (number: string): number => {
        const reformatedNumber = myNumber.reFormat(number)
        if (myNumber.isValid(reformatedNumber))
            return reformatedNumber
        return 0
    }
}

export const noAccessMessage = `You do not have the necessary permissions to access this resource.`

export const debtTypes: string[] = [
    "creditor",
    "debtor",
]

export const getAccountTypes = (accountType: AccountType): any[] => {
    try {

        if (accountType === "employee")
            return [
                { label: text.reFormat("branch employee"), value: "employee" },
            ]
        if ((accountType === "owner") || (accountType === "agent"))
            return [
                { label: text.reFormat("branch owner"), value: "owner" },
                { label: text.reFormat("branch employee"), value: "employee" },
            ]
        if (accountType === "system")
            return [
                { label: text.reFormat("agent account"), value: "agent" },
                { label: text.reFormat("branch owner"), value: "owner" },
                { label: text.reFormat("system account"), value: "system" },
                { label: text.reFormat("branch employee"), value: "employee" },
            ]
        if (accountType === "onepos")
            return [
                // { label: text.reFormat("onepos"), value: "onepos" },
                { label: text.reFormat("agent account"), value: "agent" },
                { label: text.reFormat("branch owner"), value: "owner" },
                { label: text.reFormat("system account"), value: "system" },
                { label: text.reFormat("branch employee"), value: "employee" },
            ]

        return []

    } catch (error) {
        toast.error((error as Error).message)
        return []
    }
}

export const paymentTypes: any[] = [
    { label: text.reFormat("service fee"), value: "service_fee" },
    { label: text.reFormat("sms purchase"), value: "sms_purchase" },
    { label: text.reFormat("installation fee"), value: "installation_fee" },
    { label: text.reFormat("sms sender name registration"), value: "sms_sender_name_registration" },
]

export const branchTypes: any[] = [
    { label: "Pharmacy", value: "pharmacy" },
    { label: "Hardware Store", value: "hardware" },
    { label: "Stationery Store", value: "stationery" },
    { label: "Grocery Store", value: "grocery" },
    { label: "Clothing Store", value: "clothing" },
    { label: "Electronics Store", value: "electronics" },
    { label: "Bookstore", value: "bookstore" },
    { label: "Supermarket", value: "supermarket" },
    { label: "Furniture Store", value: "furniture" },
    { label: "Toy Store", value: "toy" },
    { label: "Minimarket", value: "minimarket" },
    { label: "other", value: "other" },
]

export const paymentModes = [
    { value: 'cash', label: 'Cash' },
    { value: 'cheque', label: 'Cheque' },
    { value: 'emoney', label: 'eMoney' },
    { value: 'invoice', label: 'Invoice' },
    { value: 'credit', label: 'Credit' },
    { value: 'credit_card', label: 'Credit Card' },
]

export const formatTin = (input: any) => {
    try {

        input = input.toString()

        input = `${input.slice(0, 3)}-${input.slice(3, 6)}-${input.slice(6)}`

        return input

    } catch (error) {
        toast.error((error as Error).message)
        return input
    }
}

export const formatDate = (date: any): string => {
    // (${moment(date).subtract(3, "hours").startOf("millisecond").fromNow()})
    return `${moment(date).format("ll")}`
}

export const formatDataNumber = (number: string, branchName: string): string => {
    try {
        // Ensure the number is padded to 5 digits
        number = number?.padStart(4, '0')

        // const appName = "OP"
        const branch = text.abbreviate(text.reFormat(branchName))
        // const year = new Date().getFullYear().toString().substring(2)
        const formattedNumber = `${branch} ${number}`

        return formattedNumber
    } catch (error) {
        toast.error((error as Error).message)
        return number
    }
}


export const modules: string[] = [
    'debts',
    'roles',
    'sales',
    'users',
    'stores',
    'orders',
    'branches',
    'expenses',
    'payments',
    'products',
    'categories',
    'suppliers',
    'customers',
    'activities',
    'purchases',
    'adjustments',
    'debt_histories',
    'expense_types',
    "transactions",
    "accounts",
    "services",
    "devices",
    "requests"
]

export const reportTypes = [
    { label: "sales", value: "sales" },
    { label: "debts", value: "debts" },
    { label: "orders", value: "orders" },
    { label: "proforma", value: "proforma" },
    { label: "payments", value: "payments" },
    { label: "expenses", value: "expenses" },
    { label: "purchases", value: "purchases" },
    { label: "activities", value: "activities" },
    { label: "adjustments", value: "adjustments" },
]

export const years = (): number[] => {
    try {

        const generatedYears: number[] = []
        const currentYear: number = new Date().getFullYear()

        for (let year = 2020; year <= currentYear; year += 1)
            generatedYears.push(year)

        return generatedYears

    } catch (error) {
        toast.error(`Years generation error: ${(error as Error).message}`)
        return [new Date().getFullYear()]
    }
}

export const removedColumns: string[] = [
    "branch", "branch_name",  "created_at", "created_by", "created_by_name", "updated_by_name", "customer", "customer_company", "customer_district", "customer_email", "customer_phone_number", "customer_region", "customer_tin_number", "customer_vrn_number", "customer_pobox", "note", "is_deleted", "due_date", "id", "updated_at", "updated_by", "updated_by_name", "reference", "sales", "number",  "expense", "expense_name", "purchase", "purchase_number", "sale", "sale_number", "date", "remarks", "service", "product", "expense_type", "account", "account_name", "supplier", "status", "cogs", "product_code"
]

type AnyObject = { [key: string]: any };

export const reorderObjectWithPriorityKeys = (obj: AnyObject): AnyObject => {
  const orderedObj: AnyObject = {};

  // Define the priority keys in the desired order
  const priorityKeys = ["PRODUCT NAME", "CUSTOMER NAME", "NAME", "EXPENSE TYPE NAME"];

  // Add priority keys first, if they exist in the object
  priorityKeys.forEach(key => {
    if (key in obj) {
      orderedObj[key] = obj[key];
    }
  });

  // Add the remaining keys that are not in the priority list
  Object.keys(obj).forEach(key => {
    if (!priorityKeys.includes(key)) {
      orderedObj[key] = obj[key];
    }
  });

  return orderedObj;
};