import { lazy } from "react"
import { routerProps } from "../types"

export type route = {
    path: string,
    guest: boolean,
    component: React.LazyExoticComponent<React.FunctionComponent<routerProps>>
}

const routes: route[] = [

    {
        guest: true,
        path: "/",
        component: lazy(() => import("../pages/user/login")),
    },

    /* CATEGORY ROUTES */
    {
        guest: false,
        path: "/inventory/category-form",
        component: lazy(() => import("../pages/inventory/category/form"))
    },
    {
        guest: false,
        path: "/inventory/category-list",
        component: lazy(() => import("../pages/inventory/category/list"))
    },
    {
        guest: false,
        path: "/inventory/category-view",
        component: lazy(() => import("../pages/inventory/category/view"))
    },

    /* PRODUCT ROUTES */
    {
        guest: false,
        path: "/inventory/product-form",
        component: lazy(() => import("../pages/inventory/product/form"))
    },
    {
        guest: false,
        path: "/inventory/product-list",
        component: lazy(() => import("../pages/inventory/product/list"))
    },
    {
        guest: false,
        path: "/inventory/product-view",
        component: lazy(() => import("../pages/inventory/product/view"))
    },

    /* STOCK ADJUSTMENT ROUTES */
    {
        guest: false,
        path: "/inventory/stock-adjustment-form",
        component: lazy(() => import("../pages/inventory/adjustment/form"))
    },
    {
        guest: false,
        path: "/inventory/stock-adjustment-list",
        component: lazy(() => import("../pages/inventory/adjustment/list"))
    },
    {
        guest: false,
        path: "/inventory/stock-adjustment-view",
        component: lazy(() => import("../pages/inventory/adjustment/view"))
    },

    /* STORE ROUTES */
    {
        guest: false,
        path: "/inventory/store-form",
        component: lazy(() => import("../pages/inventory/store/form"))
    },
    {
        guest: false,
        path: "/inventory/store-list",
        component: lazy(() => import("../pages/inventory/store/list"))
    },
    {
        guest: false,
        path: "/inventory/store-view",
        component: lazy(() => import("../pages/inventory/store/view"))
    },

    /* STOCK REQUEST ROUTES */
    {
        guest: false,
        path: "/inventory/stock-request-form",
        component: lazy(() => import("../pages/inventory/stock/request-form"))
    },
    {
        guest: false,
        path: "/inventory/stock-request-list",
        component: lazy(() => import("../pages/inventory/stock/request-list"))
    },

    /* CUSTOMER ROUTES */
    {
        guest: false,
        path: "/customer/form",
        component: lazy(() => import("../pages/customer/form"))
    },
    {
        guest: false,
        path: "/customer/list",
        component: lazy(() => import("../pages/customer/list"))
    },
    {
        guest: false,
        path: "/customer/view",
        component: lazy(() => import("../pages/customer/view"))
    },

    /* PROCUREMENT ROUTES */
    /* SUPPLIER ROUTES */
    {
        guest: false,
        path: "/procurement/supplier-form",
        component: lazy(() => import("../pages/procurement/supplier/form"))
    },
    {
        guest: false,
        path: "/procurement/supplier-list",
        component: lazy(() => import("../pages/procurement/supplier/list"))
    },
    {
        guest: false,
        path: "/procurement/supplier-view",
        component: lazy(() => import("../pages/procurement/supplier/view"))
    },

    /* PURCHASE ROUTES */
    {
        guest: false,
        path: "/procurement/purchase-form",
        component: lazy(() => import("../pages/procurement/purchase/form"))
    },
    {
        guest: false,
        path: "/procurement/purchase-list",
        component: lazy(() => import("../pages/procurement/purchase/list"))
    },
    {
        guest: false,
        path: "/procurement/purchase-view",
        component: lazy(() => import("../pages/procurement/purchase/view"))
    },

    /* BRANCH ROUTES */
    {
        guest: false,
        path: "/branch/form",
        component: lazy(() => import("../pages/branch/form"))
    },
    {
        guest: false,
        path: "/branch/list",
        component: lazy(() => import("../pages/branch/list"))
    },
    {
        guest: false,
        path: "/branch/view",
        component: lazy(() => import("../pages/branch/view"))
    },
    {
        guest: false,
        path: "/branch/data",
        component: lazy(() => import("../pages/branch/data"))
    },
    {
        guest: false,
        path: "/branch/activities",
        component: lazy(() => import("../pages/branch/activity/activities"))
    },
    {
        guest: false,
        path: "/branch/activity-view",
        component: lazy(() => import("../pages/branch/activity/view"))
    },
    {
        guest: false,
        path: "/branch/settings",
        component: lazy(() => import("../pages/branch/settings"))
    },

    /* FINANCE ROUTES */
    /* DEBT ROUTES */
    {
        guest: false,
        path: "/finance/debt-form",
        component: lazy(() => import("../pages/finance/debt/form"))
    },
    {
        guest: false,
        path: "/finance/debt-list",
        component: lazy(() => import("../pages/finance/debt/list"))
    },
    {
        guest: false,
        path: "/finance/debt-view",
        component: lazy(() => import("../pages/finance/debt/view"))
    },
    {
        guest: false,
        path: "/finance/debt-history-form",
        component: lazy(() => import("../pages/finance/debt-history/form"))
    },
    {
        guest: false,
        path: "/finance/debt-history-list",
        component: lazy(() => import("../pages/finance/debt-history/list"))
    },
    {
        guest: false,
        path: "/finance/debt-history-view",
        component: lazy(() => import("../pages/finance/debt-history/view"))
    },

    /* EXPENSE ROUTES */
    {
        guest: false,
        path: "/finance/expense-form",
        component: lazy(() => import("../pages/finance/expense/form"))
    },
    {
        guest: false,
        path: "/finance/expense-list",
        component: lazy(() => import("../pages/finance/expense/list"))
    },
    {
        guest: false,
        path: "/finance/expense-view",
        component: lazy(() => import("../pages/finance/expense/view"))
    },

    /* EXPENSE TYPE ROUTES */
    {
        guest: false,
        path: "/finance/expense-type-form",
        component: lazy(() => import("../pages/finance/expense-type/form"))
    },
    {
        guest: false,
        path: "/finance/expense-type-list",
        component: lazy(() => import("../pages/finance/expense-type/list"))
    },
    {
        guest: false,
        path: "/finance/expense-type-view",
        component: lazy(() => import("../pages/finance/expense-type/view"))
    },

    /* PAYMENT ROUTES */
    {
        guest: false,
        path: "/finance/payment-form",
        component: lazy(() => import("../pages/finance/payment/form"))
    },
    {
        guest: false,
        path: "/finance/payment-list",
        component: lazy(() => import("../pages/finance/payment/list"))
    },
    {
        guest: false,
        path: "/finance/payment-view",
        component: lazy(() => import("../pages/finance/payment/view"))
    },


    /* EMPLOYEE ROUTES */
    {
        guest: false,
        path: "/employee/form",
        component: lazy(() => import("../pages/employee/form"))
    },
    {
        guest: false,
        path: "/employee/list",
        component: lazy(() => import("../pages/employee/list"))
    },
    {
        guest: false,
        path: "/employee/view",
        component: lazy(() => import("../pages/employee/view"))
    },

    /* ROLE ROUTES */
    {
        guest: false,
        path: "/employee/role-form",
        component: lazy(() => import("../pages/employee/role/form"))
    },
    {
        guest: false,
        path: "/employee/role-list",
        component: lazy(() => import("../pages/employee/role/list"))
    },
    {
        guest: false,
        path: "/employee/role-view",
        component: lazy(() => import("../pages/employee/role/view"))
    },

    /* POINT OF SALE */
    {
        guest: false,
        path: "/point-of-sale/sale-form",
        component: lazy(() => import("../pages/point-of-sale/sale/form"))
    },
    {
        guest: false,
        path: "/point-of-sale/sale-list",
        component: lazy(() => import("../pages/point-of-sale/sale/list"))
    },
    {
        guest: false,
        path: "/point-of-sale/sale-view",
        component: lazy(() => import("../pages/point-of-sale/sale/view"))
    },
    {
        guest: false,
        path: "/point-of-sale/order-form",
        component: lazy(() => import("../pages/point-of-sale/sale/form"))
    },
    {
        guest: false,
        path: "/point-of-sale/order-list",
        component: lazy(() => import("../pages/point-of-sale/order/list"))
    },
    {
        guest: false,
        path: "/point-of-sale/order-view",
        component: lazy(() => import("../pages/point-of-sale/order/view"))
    },

    {
        guest: false,
        path: "/point-of-sale/proforma-form",
        component: lazy(() => import("../pages/point-of-sale/sale/form"))
    },
    {
        guest: false,
        path: "/point-of-sale/proforma-list",
        component: lazy(() => import("../pages/point-of-sale/order/list"))
    },
    {
        guest: false,
        path: "/point-of-sale/proforma-view",
        component: lazy(() => import("../pages/point-of-sale/order/view"))
    },

    // SETTING ROUTES
    {
        guest: false,
        path: "/settings/change-password",
        component: lazy(() => import("../pages/settings/change-password"))
    },
    {
        guest: false,
        path: "/settings/employee",
        component: lazy(() => import("../pages/settings/employee"))
    },
    {
        guest: false,
        path: "/settings/edit-profile",
        component: lazy(() => import("../pages/employee/form"))
    },

    {
        guest: false,
        path: "/report/form",
        component: lazy(() => import("../pages/report/form"))
    },

    {
        guest: false,
        path: "/report/income-statement",
        component: lazy(() => import("../pages/report/income-statement"))
    },

    // DASHBOARD
    {
        guest: false,
        path: "/",
        component: lazy(() => import("../pages/dashboard/index"))
    },    {
        guest: false,
        path: "/dashboard",
        component: lazy(() => import("../pages/dashboard/index"))
    },

    // all routes
    {
        guest: true,
        path: "*",
        component: lazy(() => import("../pages/user/login")),
    },

    {
        guest: false,
        path: "*",
        component: lazy(() => import("../pages/404/page-not-found")),
    },

]

export default routes