import React from "react"
import { ChildrenType, LanguageContextType } from "../types"
import { useAuth } from "./auth"
import { string } from "fast-web-kit"
import LanguageResources from "../utils/language-resources"
import { text } from "../utils"

const LanguageContext = React.createContext<LanguageContextType>({
    language: "english",
    translate: (key: string) => key
})

export const useLanguage = () => React.useContext(LanguageContext)

export const LanguageProvider: React.FunctionComponent<ChildrenType> = React.memo(({ children }) => {

    const { user } = useAuth()
    const [language, setLanguage] = React.useState<"swahili" | "english">("english")

    React.useEffect(() => {
        getLanguage()
        // eslint-disable-next-line
    }, [user])

    const translate = (key: string): string => {
        try {
            if (string.isEmpty(key))
                return key

            if (language === "english")
                return text.reFormat(key)

            key = string.toSnakeCase(key)

            const translatedWord = LanguageResources.swahili[key] || string.removeCase(key, "snake_case")

            return text.reFormat(translatedWord.trim())

        } catch (error) {
            console.log((error as Error).message)
            return key
        }
    }

    async function getLanguage() {
        try {
            if (user) {
                const userLanguage = user.settings.language
                if (userLanguage && (language !== userLanguage))
                    setLanguage(userLanguage)
            }
        } catch (error) {
            console.log((error as Error).message)
        }
    }

    return (
        <LanguageContext.Provider value={{ language, translate }}>
            {children}
        </LanguageContext.Provider>
    )

})