/* Dependencies */
import React from "react"
import initialState from "../hooks/initial-state"
import { ChildrenType, StateContextType } from "../types"
import Reducer from "../hooks/reducer"

const StateContext = React.createContext<StateContextType>({
    state: initialState,
    dispatch: () => { },
    clearState: () => { }
})

export const useCustomState = () => React.useContext(StateContext)

export const StateProvider: React.FunctionComponent<ChildrenType> = React.memo(({ children }) => {

    const [state, dispatch] = React.useReducer(Reducer, initialState)

    const clearState = () => dispatch({ clear: true, loading: false, disabled: false });

    return (
        <StateContext.Provider value={{ state, clearState, dispatch }}>
            {children}
        </StateContext.Provider>
    )

})