import { toast } from "react-toastify"
import { encrypt, endPoint, removedColumns, reorderObjectWithPriorityKeys, serverURL, storage, text } from "."
import StateType from "../hooks"
import * as XLSX from "xlsx"
import FileSaver from "file-saver"
import { CreateOrUpdateType, DispatchType, GetListType, ReadOrDeleteType, ServerResponseType, StateKeyType } from "../types"
import pluralize from "pluralize"
import { array, string } from "fast-web-kit"

class Application {

    private user: any
    private state: StateType
    private serverURL: string
    public buttonTitle: string
    private headers: HeadersInit
    private dispatch: DispatchType

    constructor(user: any, state: StateType, dispatch: DispatchType) {
        this.user = user
        this.state = state
        this.dispatch = dispatch
        this.serverURL = serverURL
        this.buttonTitle = this.state.edit ? "modify" : "create"
        this.headers = {
            "Content-Type": "application/json",
            "Authorization": this.user ? encrypt(this.user.id).payload : ""
        }
    }

    public handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        try {
            const { name, value } = event.target
            this.dispatch({ [name]: value })

        } catch (error) {
            toast.error((error as Error).message)
        }
    }

    public readOrDelete = async (options: ReadOrDeleteType): Promise<ServerResponseType> => {
        try {

            this.dispatch({ loading: options.loading, disabled: options.disabled })

            let requestResponse = await (await fetch(`${this.serverURL}/${options.route}?${options.parameters}`, {
                method: options.method,
                mode: "cors",
                headers: this.headers
            })).json()

            if (!requestResponse.success) {
                if (requestResponse.message.includes("no active session")) {
                    storage.clear()
                    window.location.reload()
                }
            }

            return requestResponse

        } catch (error) {
            return { success: false, message: (error as Error).message }
        } finally {
            this.dispatch({ loading: false, disabled: false })
        }
    }

    public createOrUpdate = async (options: CreateOrUpdateType): Promise<ServerResponseType> => {
        try {

            this.dispatch({ loading: options.loading, disabled: options.disabled })

            let requestResponse: ServerResponseType = await (await fetch(`${this.serverURL}/${options.route}`, {
                mode: "cors",
                headers: this.headers,
                method: options.method,
                body: JSON.stringify(options.body)
            })).json()

            if (!requestResponse.success) {
                if (requestResponse.message.includes("no active session")) {
                    storage.clear()
                    window.location.reload()
                }
            }

            return requestResponse

        } catch (error) {
            return { success: false, message: (error as Error).message }
        } finally {
            this.dispatch({ loading: false, disabled: false })
        }
    }

    // function for opening and closing sidebar
    public toggleSidebar = (): void => {
        try {
            // getting body document element
            const body = document.querySelector("body")

            // checking wether body is available
            if (body) {

                // checking wether sidebar has toggle-sidebar class
                if (body.classList.contains("toggle-sidebar"))
                    // removing class ie: opening sidebar
                    body.classList.remove("toggle-sidebar")

                else
                    // adding class ie: closing sidebar
                    body.classList.add("toggle-sidebar")
            }

        } catch (error) {
            toast((error as Error).message)
        }
    }

    // autoclose sidebar on medium screen and below
    public closeSidebar = (): void => {
        try {
            if (window.screen.availWidth <= 1199)
                this.toggleSidebar()
        } catch (error) {
            toast((error as Error).message)
        }
    }

    public toggleComponent(name: "modal" | "dialog"): void {
        try {

            const component: HTMLElement | null = document.querySelector(`.${name}`)

            if (component) {
                component.classList.toggle("show")
            }

        } catch (error) {
            toast.error((error as Error).message)
        }
    }

    public handleFileChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
        try {

            const files = event.target.files;

            if (files && (files.length > 0)) {
                this.dispatch({ files })
                this.dispatch({ filesError: "" })
                this.dispatch({ file: files[0] })

                if (files[0].type.includes("application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"))
                    this.excelToArray(files[0])
                else if (files[0].type.includes("image/")) {
                    this.dispatch({ image: files[0].name })
                }

            }
            else {
                this.dispatch({ files: null, list: [], filesError: "File is required" })
            }

        }
        catch (error) {
            toast.error((error as Error).message)
        }
    }

    // exporting excel file
    public arrayToExcel = (template: any[], fileName: string): void => {
        try {

            const newTemplate: any [] = []

            for (const data of template) {
                let newData: any = {}
                for (const key in data) {
                    if (!removedColumns.includes(key)) {
                       const  newKey = string.removeCase(key, "snake_case").toUpperCase()
                        newData[newKey] = data[key]?.toString().toUpperCase()
                    }
                }
                newTemplate.push(reorderObjectWithPriorityKeys(newData))
            }
            const workSheet = XLSX.utils.json_to_sheet(newTemplate)
            const workBook = { Sheets: { 'data': workSheet }, SheetNames: ['data'] }
            const excelBuffer = XLSX.write(workBook, { bookType: 'xlsx', type: 'array' })
            const excelData = new Blob([excelBuffer], { type: 'xlsx' })
            FileSaver.saveAs(excelData, `${text.format(fileName)}.xlsx`)
        } catch (error) {
            toast.error((error as Error).message)
        }
    }

    // convert excel file to JSON
    public excelToArray = (file: any, callback?: any): void => {
        try {
            const fileReader = new FileReader()
            fileReader.onload = () => {
                const workbook = XLSX.read(fileReader.result, { type: "binary" })
                const workSheetName = workbook.SheetNames[0]
                const workSheet = workbook.Sheets[workSheetName]
                const list = XLSX.utils.sheet_to_json(workSheet)
                this.dispatch({ list })
                if (callback)
                    callback(list)
            }
            fileReader.readAsBinaryString(file)
        } catch (error) {
            toast.error((error as Error).message)
        }
    }

    public getList = async (options: GetListType) => {
        try {

            const collection = pluralize(options.module) as StateKeyType
            this.dispatch({
                collection,
                sort: options.sort,
                order: options.order,
                module: options.module,
                condition: options.condition,
            })

            const response = await this.readOrDelete({
                method: "GET",
                loading: true,
                disabled: false,
                parameters: options.parameters,
                route: endPoint + (options.module.includes("type") ? "expense-type" : options.module.includes("history") ? "debt-history" : options.module) + "/list"
            })

            if (response.success) {

                this.dispatch({
                    limit: response.message.limit,
                    page: response.message.current_page,
                    [collection]: response.message.data,
                    pages: response.message.total_pages,
                    nextPage: response.message.next_page,
                    previousPage: response.message.previous_page,
                })

                this.pagination(response.message)

            } else {
                this.dispatch({ [collection]: [] })
                toast.error(response.message)
            }


        } catch (error) {
            toast.error((error as Error).message)
        }
    }

    private pagination = (data: any): void => {

        const { total_pages, limit, current_page: currentPage } = data

        let pages: number[] = []

        for (let index = 1; index <= total_pages; index += 1)
            pages.push(index)

        // counter to ensure we create only 10 pages pagination
        const screenWidth: number = window.screen.availWidth
        const screenLimit: number = screenWidth > 992 ? 10 : 5

        // create page numbers according to screen size
        let pageNumbers: number[] = []

        if (((pages.length <= 10) && (screenLimit === 10)) || ((pages.length <= 5) && screenLimit === 5))
            pageNumbers = pages
        else {

            let counter: number = 0
            for (let index = currentPage; counter < screenLimit; index += 1) {
                if (pages.includes(index))
                    pageNumbers.push(index)
                counter += 1
            }

            if ((pageNumbers.length < 10) && (screenWidth > 992))
                pageNumbers = [...new Set([...this.state.pages, ...pageNumbers])]
            else if ((pageNumbers.length < 5) && (screenWidth <= 992))
                pageNumbers = [...new Set([...this.state.pages, ...pageNumbers])]

        }

        // create limits
        let limits: number[] = []
        for (let index = 0; index <= 1000 /* (pages.length * limit) */; index += limit)
            limits.push(index)

        // remove first element in limit, because it is 0
        limits.shift()

        limits = [...new Set([...this.state.limits, ...limits])]

        if (pageNumbers.length > 0)
            this.dispatch({ pages: pageNumbers })
        else
            this.dispatch({ pages: pageNumbers })

        if (limits.length > 0)
            this.dispatch({ limits })
        else
            this.dispatch({ limits })

    }

    public selectList = (id?: string): void => {
        try {

            if (id && string.isNotEmpty(id)) {
                if (array.elementExist(this.state.ids, id)) {
                    this.dispatch({ ids: this.state.ids.filter((listId: string) => listId !== id) })
                } else {
                    this.dispatch({ ids: [...this.state.ids, id] })
                }
            }
            else {
                // deselect all
                if ((this.state.ids.length === this.state[this.state.collection].length) && (this.state.ids.length > 0)) {
                    this.dispatch({ ids: [] })
                }
                else {
                    this.dispatch({ ids: this.state[this.state.collection].map((list: any) => list.id) })
                }
            }

        } catch (error) {
            toast.error((error as Error).message)
        }
    }

    public searchData = async (event: React.ChangeEvent<any>) => {
        try {

            event.preventDefault()
            const sort = string.toSnakeCase(this.state.sort)
            const condition = string.toSnakeCase(this.state.condition)
            const parameters = `condition=${condition}&sort=${sort}&order=${this.state.order}&keyword=${text.format(this.state.searchKeyword)}`

            const options: ReadOrDeleteType = {
                parameters,
                method: "GET",
                loading: true,
                disabled: false,
                route: endPoint + (this.state.module.includes("type") ? "expense-type" : this.state.module.includes("history") ? "debt-history" : this.state.module) + "/search"
            }

            const response = await this.readOrDelete(options)

            if (response.success) {
                this.dispatch({
                    pages: [],
                    nextPage: 0,
                    previousPage: 0,
                    [this.state.collection]: response.message
                })
            } else {
                this.dispatch({
                    pages: [],
                    nextPage: 0,
                    previousPage: 0,
                    [this.state.collection]: []
                })
                toast.error(response.message)
            }

        } catch (error) {
            toast.error((error as Error).message)
        }
    }

    public deleteData = async () => {
        try {

            this.toggleComponent("dialog")

            const options: CreateOrUpdateType = {
                method: "POST",
                loading: true,
                disabled: false,
                body: { ids: this.state.ids },
                route: endPoint + (this.state.module.includes("type") ? "expense-type" : this.state.module.includes("history") ? "debt-history" : this.state.module) + "/delete"
            }

            const response = await this.createOrUpdate(options)

            if (response.success) {
                const newList = this.state[this.state.collection].filter((data: any) => !this.state.ids.some((deletedId: any) => data.id === deletedId))
                this.dispatch({ [this.state.collection]: newList, ids: [] })
                toast.success(response.message)

            } else {
                toast.error(response.message)
            }

        } catch (error) {
            toast.error((error as Error).message)
        }
    }

    public filterData = async (condition: string, order: 1 | -1, sort: string, limit: number) => {
        try {

            if ((condition !== this.state.condition) || (order !== this.state.order) || (sort !== this.state.sort) || (limit !== this.state.limit)) {

                const parameters = `condition=${string.toSnakeCase(condition)}&sort=${string.toSnakeCase(sort)}&order=${order}&limit=${limit}&page=${1}`

                const response = await this.readOrDelete({
                    parameters,
                    method: "GET",
                    loading: true,
                    disabled: false,
                    route: endPoint + (this.state.module.includes("type") ? "expense-type" : this.state.module.includes("history") ? "debt-history" : this.state.module) + "/list"
                })

                if (response.success) {

                    this.dispatch({
                        limit: response.message.limit,
                        page: response.message.current_page,
                        pages: response.message.total_pages,
                        nextPage: response.message.next_page,
                        previousPage: response.message.previous_page,
                        [this.state.collection]: response.message.data,
                    })

                    this.pagination(response.message)

                } else {
                    this.dispatch({
                        page: 1,
                        pages: [],
                        nextPage: 0,
                        previousPage: 0,
                        [this.state.collection]: [],
                    })
                    toast.error(response.message)
                }

            }

        } catch (error) {
            toast.error((error as Error).message)
        } finally {
            this.dispatch({ condition, sort, order, limit })
        }
    }

    public paginate = async (page: number) => {
        try {

            if ((page > 0) && (page !== this.state.page)) {

                const sort = string.toSnakeCase(this.state.sort)
                const condition = string.toSnakeCase(this.state.condition)
                const parameters = `condition=${condition}&sort=${sort}&order=${this.state.order}&limit=${this.state.limit}&page=${page}`

                const response = await this.readOrDelete({
                    parameters,
                    method: "GET",
                    loading: true,
                    disabled: false,
                    route: endPoint + (this.state.module.includes("type") ? "expense-type" : this.state.module.includes("history") ? "debt-history" : this.state.module) + "/list"
                })

                if (response.success) {

                    this.dispatch({
                        limit: response.message.limit,
                        page: response.message.current_page,
                        pages: response.message.total_pages,
                        nextPage: response.message.next_page,
                        previousPage: response.message.previous_page,
                        [this.state.collection]: response.message.data,
                    })

                    this.pagination(response.message)

                } else {
                    toast.error(response.message)
                }

            }
        } catch (error) {
            toast.error((error as Error).message)
        }
    }

    public getSortOrCondition = (type: "sort" | "condition"): string[] => {
        try {

            let sorts: string[] = ["created time"]
            let conditions: string[] = [this.state.collection]

            switch (this.state.collection) {

                case "categories":
                    sorts = [...sorts, "name"]
                    break

                case "products":
                    conditions = [
                        ...conditions,
                        "out of stock",
                        "almost out of stock",
                        "expired",
                        "in stock",
                        "store products",
                        "shop products",
                        "store expired products",
                        "shop expired products",
                        "store almost out of stock",
                        "shop almost out of stock",
                        "store in stock",
                        "shop in stock",
                    ]
                    sorts = [
                        ...sorts, "name", "buying price", "selling price", "stock", "unit of measure", "placement", "expire date"
                    ]
                    break

                case "adjustments":
                    sorts = [...sorts, "type", "after adjustment", "before adjustment", "adjustment"]
                    conditions = [...conditions, "increase", "decrease", "automatic adjustments", "manual adjustments", "purchase adjustments", "sale adjustments", "service adjustments"]
                    break

                case "customers":
                    sorts = [...sorts, "name", "phone number", "tin number", "vrn number", "region", "district", "ward", "pobox", "email"]
                    conditions = [...conditions,
                        "with district",
                        "without district",
                        "with ward",
                        "without ward",
                        "with email",
                        "without email",
                        "with tin number",
                        "without tin number",
                        "with vrn number",
                        "without vrn number",
                    ]
                    break

                case "activities":
                    sorts = [...sorts, "type"]
                    conditions = [...conditions, "creation", "deletion", "modification"]
                    break

            }


            if (type === "condition") {
                return array.removeDuplicates(conditions)
            }

            return array.removeDuplicates(sorts)

        } catch (error) {
            toast.error((error as Error).message)
            return []
        }
    }


}

export default Application