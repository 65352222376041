import React from "react"
import { ChildrenType } from "../types"
import { Helmet, HelmetProvider } from "react-helmet-async"
import { useAuth } from "./auth"
import { toast } from "react-toastify"
import { useCustomState } from "./state"

const ThemeContext = React.createContext<{ theme: "light" | "dark" }>({ theme: "light" })

export const useTheme = () => React.useContext(ThemeContext)

export const ThemeProvider: React.FunctionComponent<ChildrenType> = React.memo(({ children }) => {

    const darkColor = "#1B1B1B"
    const lightColor = "#F0F0F9"
    const { user, settings } = useAuth()
    const currentHour = new Date().getHours()
    const { state, dispatch } = useCustomState()
    const [theme, setTheme] = React.useState<"light" | "dark">("light")

    const getTheme = () => {
        try {
            if (user) {
                const { settings: { theme } } = user
                if (theme === "automatic") {
                    if ((currentHour >= 18) || (currentHour <= 6)) {
                        setTheme("dark")
                    } else {
                        setTheme("light")
                    }
                }
                else
                    setTheme(theme)
            }
        } catch (error) {
            toast.error((error as Error).message)
        }
    }

    React.useEffect(() => {
        getTheme()
        // eslint-disable-next-line
    }, [user, currentHour])

    // changing primary color
    React.useEffect(() => {
        console.log(state.primaryColor)
        let primaryColor: string = state.primaryColor
        document.documentElement.style.setProperty("--primary-color", primaryColor)
        // eslint-disable-next-line
    }, [state.primaryColor])

    // React.useEffect(() => {
    //     if (user?.branch) {
    //         const { branch: { settings: { primary_color } } } = user
    //         if (primary_color)
    //             dispatch({ primaryColor: primary_color })
    //     }
    //     // eslint-disable-next-line
    // }, [])

    return (
        <ThemeContext.Provider value={{ theme }}>
            <HelmetProvider>
                <Helmet>
                    {/* <link rel="stylesheet" href={`/${theme}.css`} /> */}
                    <meta name="theme-color" content={theme === "light" ? lightColor : darkColor} />
                    <meta name="msapplication-TileColor" content={theme === "light" ? lightColor : darkColor} />
                    <link rel="mask-icon" href="/safari-pinned-tab.svg" color={theme === "light" ? lightColor : darkColor} />
                </Helmet>
                {children}
            </HelmetProvider>
        </ThemeContext.Provider>
    )

})
