/* dependencies */
import React from "react"
import { Icon } from "./elements"
import { applicationName, text } from "../utils"
import { useLanguage } from "../context/language"
import { useAuth } from "../context/auth"
import { useApplication } from "../context/application"

type breadcrumb = {
    icon: string
    title: string
}

/* breadcrumb memorized functional component */
const Breadcrumb: React.FunctionComponent<breadcrumb> = React.memo((props: breadcrumb) => {

    const { user, logout } = useAuth()
    const { translate } = useLanguage()
    const { application } = useApplication()


    return (
        <div className="breadcrumb hide-on-print">
            <div className="section" onClick={application.toggleSidebar}>
                <Icon name={props.icon} />
                <span className="title">{translate(props.title)}</span>
            </div>
            <div className="center-section">
                <span className="name">{user.branch_name ? text.reFormat(user.branch_name) : applicationName}</span>
            </div>
            <div className="section right-section" onClick={logout}>
                <Icon name={"logout"} />
                <span className="title">{translate("logout")}</span>
            </div>
        </div>
    )
})

/* exporting component */
export default Breadcrumb