import React, { Suspense } from "react"
import { useAuth } from "./context/auth"
import routes, { route } from "./routes"
import 'react-toastify/dist/ReactToastify.css';
import { Switch, BrowserRouter as Router, Route } from "react-router-dom"
import { ToastContainer } from "react-toastify";
import { useTheme } from "./context/theme";
import Loader from "./components/loader";
import Sidebar from "./components/sidebar";

const App: React.FunctionComponent = React.memo(() => {

  const { user } = useAuth()
  const { theme } = useTheme()
  const history = window.history

  React.useEffect(() => {
    const handlePopstate = (event: any) => {
      // Check if can go back or forward
      if (event.state) {
        // Go back or forward to the previous/next state in History
        history.go(event.state.delta);
      }
    };

    window.addEventListener('popstate', handlePopstate);

    return () => {
      window.removeEventListener('popstate', handlePopstate);
    };
    // eslint-disable-next-line
  }, [history]);

  return (
    <Suspense fallback={<Loader loading={true} />}>
      <Loader />
      <main id={user ? "main" : ""}>
        <Router>
          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme={theme}
          />
          <Switch>
            {
              routes.map((route: route, index: number) => {

                // unprotected routes
                if (route.guest && !user)
                  return (
                    <Route
                      key={index}
                      path={route.path}
                      render={(props) => (<route.component {...props} />)}
                      exact
                    />
                  )

                // protected routes
                if (!route.guest && user) {
                  return (
                    <Route
                      key={index}
                      path={route.path}
                      render={(props) => (<route.component {...props} />)}
                      exact
                    />
                  )
                }

                return null

              })
            }
          </Switch>
          <Sidebar />
        </Router>
      </main>
    </Suspense>
  )

})

export default App