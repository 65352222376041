/* dependencies */
import React from "react"
import { useCustomState } from "../context/state"

/* loader */
const Loader: React.FunctionComponent<{ loading?: boolean }> = React.memo(({ loading }) => {

    // application context
    const { state } = useCustomState()

    if (loading || state.loading)
        return (
            <div className="hide-on-print">
                <div className="loader-container">
                    <div className="loader">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                </div>
            </div>
        )

    return null
})

/* exporting component */
export default Loader