import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import { StateProvider } from "./context/state";
import { ApplicationProvider } from "./context/application";
import { AuthProvider } from "./context/auth";
import { LanguageProvider } from "./context/language";
import { ThemeProvider } from "./context/theme";
import "./sass/light.scss"

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <AuthProvider>
      <LanguageProvider>
        <StateProvider>
          <ApplicationProvider>
            <ThemeProvider>
              <App />
            </ThemeProvider>
          </ApplicationProvider>
        </StateProvider>
      </LanguageProvider>
    </AuthProvider>
  </React.StrictMode>
)
