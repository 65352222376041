/* Dependencies */
import React from "react"
import Application from "../utils/application"
import initialState from "../hooks/initial-state"
import { ChildrenType } from "../types"
import { useCustomState } from "./state"
import { useAuth } from "./auth"

const ApplicationContext = React.createContext<{ application: Application }>({
    application: new Application(null, initialState, () => { })
})

export const useApplication = () => React.useContext(ApplicationContext)

export const ApplicationProvider: React.FunctionComponent<ChildrenType> = React.memo(({ children }) => {

    const { user } = useAuth()
    const { state, dispatch } = useCustomState()
    const application = new Application(user, state, dispatch)

    return (
        <ApplicationContext.Provider value={{ application }}>
            {children}
        </ApplicationContext.Provider>
    )

})