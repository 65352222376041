// Dependencies
import React from "react"
import { AuthContextType, ChildrenType } from "../types"
import { encrypt, endPoint, serverURL, storage } from "../utils"
import { toast } from "react-toastify"

// Creating a context for authentication management
const AuthContext = React.createContext<AuthContextType>({
    user: null,
    settings: null,
    login: () => { },
    logout: async () => { },
})

// AuthProvider component: responsible for managing authentication state and providing authentication-related functions
export const AuthProvider: React.FunctionComponent<ChildrenType> = React.memo(({ children }) => {

    const [user, setUser] = React.useState<any>(null)
    const [settings, setSettings] = React.useState<any>(null)

    React.useEffect(() => {
        getUser()
        // eslint-disable-next-line
    }, [])

    React.useEffect(() => {
        getSettings()
        // eslint-disable-next-line
    }, [user])

    const getUser = async () => {

        const userData = await storage.retrieve("user")

        if (userData) {
            setUser(userData)
        }

    }

    const getSettings = () => {
        try {

            if (user) {
                let userSettings = user.settings

                if (user.branch_settings) {
                    userSettings = { ...userSettings, ...user.branch_settings }
                }

                setSettings(userSettings)
            }

        } catch (error) {
            toast((error as Error).message)
        }
    }

    // Function to log in a user
    const login = (userData: any, showToast?: boolean) => {
        storage.store("user", userData)
        setUser(userData)
        if (showToast)
            toast("You have been logged in")
    }

    const logout = async () => {
        try {

            const URL = `${serverURL}/${endPoint}user/logout`
            const requestResponse = await (
                await fetch(URL, {
                    mode: "cors",
                    method: "PUT",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": encrypt(user.id).payload
                    },
                })
            ).json()

            if (requestResponse.success) {
                storage.clear()
                setUser(null)
                toast(requestResponse.message)
                window.location.href = "/"
            }
            else {
                toast(requestResponse.message)
            }

        } catch (error) {
            toast(
                (error as Error).message,
            )
        }
    }


    return (
        // Providing authentication context to the children components
        <AuthContext.Provider value={{ user, login, logout, settings }}>
            {children}
        </AuthContext.Provider>
    )

})

// useAuth hook: Custom hook to access authentication context
export const useAuth = () => React.useContext(AuthContext)